import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import Modules from 'components/Craft/Modules';

export default function IndexPage(props) {
  const {
    data: {
      craft: { modules },
    },
  } = props;

  return (
    <>
      <Seo />
      {modules ? <Modules modules={modules} /> : null}
    </>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    craft {
      modules: entries(section: modules) {
        ... on Craft_ModulesModule {
          ...ModulesQuery
        }
      }
    }
  }
`;
