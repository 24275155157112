import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PageBuilder from 'components/Craft/PageBuilder';
import SwitchTheme from 'components/SwitchTheme';
import Wrapper from 'components/Wrapper';
import {
  SectionStyled,
  HeaderStyled,
  SublineStyled,
  HeadlineStyled,
} from './styles';

export default function Modules({ modules }) {
  // Loop through given modules.
  return modules.map((module) => {
    const {
      id,
      slug,
      moduleTitle,
      moduleSubtitle,
      moduleTitleHidden,
      moduleThemeName,
      moduleFluid,
      modulePadded,
      moduleHideOverflow,
      pageBuilder,
    } = module;

    return (
      <SwitchTheme name={moduleThemeName} key={`${id}-${slug}`}>
        <SectionStyled id={slug} hideOverflow={moduleHideOverflow}>
          <Wrapper fluid={moduleFluid} padded={modulePadded}>
            {!moduleTitleHidden && (
              <HeaderStyled>
                <SublineStyled element="h5" numbered>
                  {moduleSubtitle}
                </SublineStyled>
                <HeadlineStyled element="h2">{moduleTitle}</HeadlineStyled>
              </HeaderStyled>
            )}
            <PageBuilder moduleSlug={slug} pageBuilder={pageBuilder} />
          </Wrapper>
        </SectionStyled>
      </SwitchTheme>
    );
  });
}

Modules.propTypes = {
  slug: PropTypes.string,
  moduleTitle: PropTypes.string,
  moduleSubtitle: PropTypes.string,
  moduleTitleHidden: PropTypes.bool,
  moduleThemeName: PropTypes.string,
  moduleFluid: PropTypes.bool,
  modulePadded: PropTypes.bool,
  moduleHideOverflow: PropTypes.bool,
};

export const query = graphql`
  fragment ModulesQuery on Craft_ModulesModule {
    id
    slug
    moduleTitle
    moduleMenuTitle
    moduleSubtitle
    moduleTitleHidden
    moduleThemeName
    moduleFluid
    modulePadded
    moduleHideOverflow
    pageBuilder {
      ...PageBuilderQuery
    }
  }
`;
